import { useEffect } from "react";
import amplitude from 'amplitude-js';

import styles from "./ChartBanner.module.scss";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import {useNavigate} from "react-router-dom";
import LazyImage from "../../UI/lazyImage/LazyImage";
import {sanitizeData} from "../../../utils/sanitizeHtmlText";

const ChartBanner = props => {
    const { data, nextStep } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.chartBanner
    const textData = strings.plan.chartBanner
    const navigate = useNavigate();
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        dataLayer.push({
                            "event": "ga4event",
                            "event_category": "screen_onboarding_plan_is_ready_appeared",
                            "funnel_type": data.campaign,
                            "step": pageInfo.order
                       });
        amplitude.getInstance().logEvent('screen_onboarding_plan_is_ready_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_plan_is_ready_continue_tapped');
        nextStep();
    }

    return (
        <div className={mainContainerClass}>
            <div className={styles.imageBox}>
                <img loading="lazy" className={styles.logoImage} src="/images/logo-black.svg" alt="" />
            </div>
            <h1 className={styles.title}>
                {textData.title[pageInfo.title]}
            </h1>
            <div className={`${styles.infoBox} flex-column`}>
                <div className={`${styles.chartArea}`}>
                    <LazyImage
                        srcWebp={pageInfo.imageWebp}
                        src={pageInfo.image}
                        alt=""
                    />
                </div>
                <p className={styles.subtitle}>
                    {textData.subtitle[pageInfo.subtitle]}
                </p>
            </div>
            <p
                className={styles.remark}
                dangerouslySetInnerHTML={{ __html: sanitizeData(textData.infoBox[pageInfo.infoBox]) }}
            ></p>
            <ContinueButton
                text={textData.continue}
                nextStep={clickContinue}
                theme={themeData.continueButton}
            />
        </div>
    )
}

export default ChartBanner;
