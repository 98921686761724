import React, { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./History.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";

const History = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.history;
    const textInfo = strings.plan.history;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_history_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_history_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title}</h1>

            {pageInfo.points && pageInfo.points.length > 0 && (
                <div className={styles.pointsBox}>
                    <ul className={styles.points} >
                        { pageInfo.points.map((point, index) => (
                            <li
                                key={index}
                                className={styles.point}
                            >
                                {textInfo.points[point.key]}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className={styles.bannerArea}>
                <div className={`${styles.bannerBox} flex-column`}>
                    <div className={`${styles.bannerTitle} flex-row`}>
                        <div className={styles.imageContainer}>
                            <img
                                className={styles.image}
                                src={pageInfo.banner.iconImage}
                                alt="bannerIcon"
                            />
                        </div>
                        <div className={styles.title}>
                            {textInfo.banner.title[pageInfo.banner.title]}
                        </div>
                    </div>
                    <div className={styles.description}>
                        {textInfo.banner.description[pageInfo.banner.description]}
                    </div>
                </div>
            </div>
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default History;