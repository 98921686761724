import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./PeopleMeet.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";
import LazyImage from "../../../UI/lazyImage/LazyImage";
import {sanitizeData} from "../../../../utils/sanitizeHtmlText";

const PeopleMeet = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.peopleMeet;
    const textInfo = strings.plan.peopleMeet;

    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const [comments, setVisibleComments] = useState([pageInfo.comments[0]]);
    const [index, setIndex] = useState(1);
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_peopleMeet_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_peopleMeet_appeared');
    // }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (index < 4) {
                setVisibleComments([...comments, pageInfo.comments[index]]);
                setIndex(index + 1);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [index, comments]);

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title[pageInfo.title]}</h1>
            <div className={`${styles.commentsBox} flex-column`}>
                {comments.map((comment, idx) => (
                    <div key={idx} className={styles.commentContainer}>
                        <div className={`${styles.titleArea} flex-row`}>
                            <div className={styles.imageContainer}>
                                <LazyImage
                                    className={styles.image}
                                    srcWebp={comment.imageWebp}
                                    src={comment.image}
                                    alt=""
                                />
                            </div>
                            <div className={`${styles.titleContainer} flex-column`}>
                                <h2>{textInfo.comments[comment.key].title}</h2>
                                <div className={styles.starsImageContainer}>
                                    <img src={comment.prefixStarsImage} alt={"prefixStarsImage"}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{ __html: sanitizeData(textInfo.comments[comment.key].text) }}
                        />
                        <div className={styles.authorContainer}>
                            {textInfo.comments[comment.key].author}
                        </div>
                    </div>
                ))}

            </div>
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default PeopleMeet;