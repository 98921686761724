import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./ProudOfUsers.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";
import CircleVideoPlayer from "../../../UI/circleVideoPlayer/CircleVideoPlayer";

const ProudOfUsers = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.proudOfUsers;
    const textInfo = strings.plan.proudOfUsers;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}
    const mutedFeedbacks = pageInfo.videos.reduce((acc, el) => {
        acc[el.id] = true;
        return acc;
    }, {});
    const [mutedVideos, setMutedVideos] = useState(mutedFeedbacks || {});

    const handleMuteAll = (key, value) => {
        setMutedVideos({...mutedFeedbacks, [key]: value })
    }

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_proudOfUsers_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_proudOfUsers_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title[pageInfo.title]}</h1>

            {pageInfo.videos && pageInfo.videos.length > 0 && (
                <div className={styles.videos}>
                    { pageInfo.videos.map((video, index) => (
                        <div key={index} className={styles.video}>
                            <CircleVideoPlayer
                                id={video.id}
                                src={video.src}
                                srcWebm={video.srcWebm}
                                poster={video.poster}
                                muteVideo={mutedVideos[video.id]}
                                handleMuteAll={handleMuteAll}
                                view={'squircle'}
                                soundless={video.soundless}
                            />
                        </div>
                    ))}
                </div>
            )}
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default ProudOfUsers;