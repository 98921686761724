import {useEffect, useState} from "react";

export function isOverheight(divHeight) {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const checkHeight = () => {
            setHeight(window.innerHeight);
        };

        checkHeight();
        window.addEventListener("resize", checkHeight);
        return () => window.removeEventListener("resize", checkHeight);
    }, []);

    return (divHeight || 0) + 88 >= height;
}