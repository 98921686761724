import React, { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./HowWeHelpYou.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";
import LazyImage from "../../../UI/lazyImage/LazyImage";

const HowWeHelpYou = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.howWeHelpYou;
    const textInfo = strings.plan.howWeHelpYou;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_howWeHelpYou_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_howWeHelpYou_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title[pageInfo.title]}</h1>

            {pageInfo.points && pageInfo.points.length > 0 && (
                <div className={styles.pointsBox}>
                    <ul className={styles.points} >
                        { pageInfo.points.map((point, index) => (
                            <li
                                key={index}
                                className={styles.point}
                            >
                                {textInfo.points[point.key]}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className={styles.imageBox}>
                <div className={styles.imageContainer}>
                    <LazyImage
                        className={styles.image}
                        srcWebp={pageInfo.imageWebp}
                        src={pageInfo.image}
                        alt="banner"
                    />
                </div>
            </div>
            <div className={styles.notion}>
                {textInfo.notion[pageInfo.notion]}
            </div>
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default HowWeHelpYou;