import styles from "./LabelWithCheckbox.module.scss";
import CustomCheckbox from "../checkbox/Checkbox";

const LabelWithCheckbox = (props) => {
    const { labelText, theme, size } = props;
    const checkboxData = {
        "checked": true,
        "disabled": true
    }
    const mainContainerClass = `${styles.labelRow} ${styles[`${theme}Container`]}`

    return (
        <div className={mainContainerClass}>
            <CustomCheckbox data={checkboxData} color={theme} checkCard={()=>{}}/>
            <p className={size === 's' ? styles.labelSmall
                            : size === 'm' ? styles.labelMedium
                            : size === 'l' ? styles.labelLarge
                            : size === 'xl' ? styles.labelXLarge
                            : styles.label}
            >
                {labelText}
            </p>
        </div>
    )
}

export default LabelWithCheckbox;