import React, { useState, useEffect } from "react";
import amplitude from 'amplitude-js';

import styles from "./DefaultResultProgress.module.scss";
import strings from "../../../../assets/localization/strings.jsx";
import CustomCheckbox from "../../../UI/checkbox/Checkbox";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import LazyImage from "../../../UI/lazyImage/LazyImage";


const ProgressBar = (props) => {
    const { tick, minTick, maxTick, title, theme } = props;

    const inBetween = tick >= minTick && tick < maxTick
    const titleClass = inBetween ? "activeLabel" : tick < minTick ? "inactiveLabel" : "finishedLabel"
    const progressBarClass = `${styles.progressBar} ${styles[`${theme}ProgressBar`]}`
    const progressValue = inBetween ? ((tick-minTick) / 100) : (tick < minTick ? 0 : 1)
    const checkboxData = {
        "checked": true,
        "disabled": true
    }

    return (
        <>
            <div className={styles.labelRow}>
                <p className={styles[titleClass]}>{title}</p>
                { inBetween
                    ? (<p className={styles.activePercent}>{(tick-minTick)}%</p>)
                    : (tick < minTick)
                        ? (<p className={styles.activePercent}>0%</p>)
                        : (<CustomCheckbox data={checkboxData} color={theme} checkCard={()=>{}}/>)}
            </div>
            <progress
                className={progressBarClass}
                value={progressValue}
            />

        </>
    )
}

const DefaultResultProgress = (props) => {
    const { data, nextStep, answers } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.defaultResultProgress;
    const textInfo = strings.plan.defaultResultProgress;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    const [tick, setTick] = useState(0);
    const [timer, setTimer] = useState();

    useEffect(() => {
        setTimer(setInterval(() => setTick(prev => prev + 1), 30));
        // dataLayer.push({
        //                                           "event": "ga4event",
        //                                           "event_category": 'screen_onboarding_defaultResultProgress_appeared',
        //                                           "funnel_type": data.campaign,
        //                                            "step": pageInfo.order
        //                          });
        // amplitude.getInstance().logEvent(`screen_onboarding_defaultResultProgress_appeared`);
        return () => {
            setTick(0);
            clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        if(tick === 300) {
            clearInterval(timer);
            setTimeout(async () => {
                 let requestMap = {};
                 requestMap["platform"] = "web";
                 requestMap["campaign"] = data.campaign;
                 answers.forEach((value, key) => {
                    requestMap[key] = value
                 });

                await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/create_registration_statistics", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestMap),
                }).then(async (response) => {

                    let resultBody = response.json();
                    await resultBody.then( async (json) => {
                        localStorage.setItem('onboarding_id', json.body.id);
                    });
                });
                amplitude.getInstance().logEvent('onboarding_finish');
                nextStep();
            }, 1000)
        }
    }, [tick])

    return (
        <div className={mainContainerClass}>
            <h1 className={styles.title}>{textInfo.title[pageInfo.title]}</h1>
            {pageInfo.progress && pageInfo.progress.length > 0 && (
                <div className={styles.progress}>
                    { pageInfo.progress.map((point, index) => (
                        <div key={index} className={styles.progressPoint}>
                            <ProgressBar
                                tick={tick}
                                minTick={index * 100}
                                maxTick={(index+1) * 100}
                                title={textInfo.progress[point.key].text}
                                theme={themeData.checkboxCardColor}
                            />
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.infoContainer}>
                <div className={styles.imageContainer}>
                    <div className={styles.infoImage}>
                        <LazyImage
                            className={styles.image}
                            srcWebp={pageInfo.peopleImageWebp}
                            src={pageInfo.peopleImage}
                            alt="trusted"
                        />
                    </div>
                </div>
                <div className={styles.infoTitle}>
                    {textInfo.description[pageInfo.description]}
                </div>
            </div>
            {pageInfo.comments && pageInfo.comments.length > 0 && (
                <div className={styles.commentsContainer} >
                    <Swiper
                        className={styles.greenSwiper}
                        slidesPerView={1}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        { pageInfo.comments.map((comment, i) => (
                            <SwiperSlide key={i} className={styles.swiperSlide}>
                                <div className={styles.slideContainer} >
                                    <div className={styles.commentContainer} >
                                        <div className={styles.imageContainer}>
                                            <div className={styles.imageBox}>
                                                <img src={comment.starsImage} alt={"starsImage"}/>
                                            </div>
                                        </div>
                                        <p>{textInfo.comments[comment.key].text}</p>
                                        <div className={styles.authorContainer}>
                                            {textInfo.comments[comment.key].author}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </div>
    )
}

export default DefaultResultProgress;