import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./Familiar.module.scss";
import {sanitizeData} from "../../../../utils/sanitizeHtmlText";
import {isOverheight} from "../../../../utils/checkOverheight";

const Familiar = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.familiar;
    const textInfo = strings.plan.familiar;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_familiar_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_familiar_appeared');
    // }, [])

    const clickButton = value => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped', {answer: value});
        const answer = new Map();
        answer.set("key", "Familiar");
        answer.set("value", value);
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title}</h1>
            {pageInfo.points && pageInfo.points.length > 0 && (
                <div className={styles.pointsBox}>
                    <ul className={styles.points}>
                        { pageInfo.points.map((point, index) => (
                            <li key={index} className={styles.point} >
                                <div
                                    dangerouslySetInnerHTML={{ __html: sanitizeData(textInfo.points[point.key]) }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className={styles.quotationBox}>
                <div className={styles.quotation}>
                    {textInfo.quotation[pageInfo.quotation.text]}
                </div>
            </div>
            <div
                className={`${styles.buttonsContainer} flex-between`}
                style={buttonStyle}
            >
                <button
                    className={`${styles.button} ${styles.firstButton}`}
                    onClick={() => clickButton('no')}
                >
                    No
                </button>
                <button
                    className={`${styles.button} ${styles.secondButton}`}
                    onClick={() => clickButton('yes')}
                >
                    Yes
                </button>
            </div>
        </div>
    )
}   

export default Familiar;