import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./JoinCommunity.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/navigation";
import LazyImage from "../../../UI/lazyImage/LazyImage";
import {isOverheight} from "../../../../utils/checkOverheight";
import {sanitizeData} from "../../../../utils/sanitizeHtmlText";

const JoinCommunity = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.joinCommunity;
    const textInfo = strings.plan.joinCommunity;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const swiperRef = React.useRef(null)
    const [swiperEl, setSwiperEl] = useState(swiperRef.current)
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    useEffect(() => {
        if(swiperRef.current)
            setSwiperEl(swiperRef.current)
    }, [swiperRef]);

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_joinCommunity_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_joinCommunity_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title[pageInfo.title]}</h1>

            {pageInfo.slides && pageInfo.slides.length > 0 && (
                <div
                    ref={swiperRef}
                    className={styles.slidersBox}
                >
                    <Swiper
                        className={styles.swiperContainer}
                        slidesPerView={1}
                        centeredSlides={true}
                        navigation={true}
                    >
                        { pageInfo.slides.map((slide, i) => (
                            <SwiperSlide key={i} className={styles.swiperSlide}>
                                <div
                                    className={styles.slideContainer}
                                >
                                    <div className={styles.imageContainer}>
                                        <LazyImage
                                            className={styles.image}
                                            srcWebp={slide.imageWebp}
                                            src={slide.image}
                                            alt=""
                                        />
                                    </div>

                                    <div className={styles.commentContainer}>
                                        <div className={`${styles.titleArea} flex-row`}>
                                            <div className={styles.imageContainer}>
                                                <LazyImage
                                                    className={styles.image}
                                                    srcWebp={slide.photoWebp}
                                                    src={slide.photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={`${styles.titleContainer} flex-column`}>
                                                <h2>{textInfo.slides[slide.key].title}</h2>
                                                <div className={styles.starsImageContainer}>
                                                    <img src={slide.prefixStarsImage} alt={"prefixStarsImage"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={styles.description}
                                            dangerouslySetInnerHTML={{ __html: sanitizeData(textInfo.slides[slide.key].text) }}
                                        />
                                        <div className={styles.authorContainer}>
                                            {textInfo.slides[slide.key].author}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default JoinCommunity;