import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./GoodHands.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";
import LazyImage from "../../../UI/lazyImage/LazyImage";

const GoodHands = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.goodHands;
    const textInfo = strings.plan.goodHands;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_goodHands_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_goodHands_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <div className={styles.mainImage}>
                <div className={styles.imageContainer}>
                    <LazyImage
                        className={styles.image}
                        srcWebp={pageInfo.imageWebp}
                        src={pageInfo.image}
                        alt="image"
                    />
                </div>
            </div>
            <h1>{textInfo.title[pageInfo.title]}</h1>
            <div className={styles.quotationBox}>
                <div className={styles.quotation}>
                    {textInfo.quotation[pageInfo.quotation.text]}
                </div>
            </div>
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default GoodHands;