import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./StatementDescribe.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";
import CustomCheckbox from "../../../UI/checkbox/Checkbox";

const StatementDescribe = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.statementDescribe;
    const textInfo = strings.plan.statementDescribe;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    const [activeCards, setActiveCards] = useState([]);
    const [countSelectedCards, setCountSelectedCards] = useState(0);

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_statementDescribe_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_statementDescribe_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped', {answer: activeCards});
        const answer = new Map();
        answer.set("key", "health_challenges");
        answer.set("value", activeCards);
        nextStep();
    }

    const selectCard = (card) => {
        const filteredCards = activeCards.includes(card.key) ?
            activeCards.filter((el) => el !== card.key) :
            [...activeCards, card.key]

        console.log("filteredCards", filteredCards)
        setActiveCards(filteredCards)
        setCountSelectedCards(filteredCards.length);
    }

    const selectNoCard = () => {
        setActiveCards([])
        amplitude.getInstance().logEvent('button_onboarding_statementDescribe_tapped', {answer: 'None above'});
        setCountSelectedCards(1);
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title[pageInfo.title]}</h1>

            {pageInfo.points && pageInfo.points.length > 0 && (
                <div className={`${styles.pointsBox} flex-column`}>
                    { pageInfo.points.map((point, index) => (
                        <div
                            key={index}
                            className={`${styles.cardWrapper} flex-row cardContainer ${activeCards.includes(point.key) ? styles.activeCard : ''}`}
                            onClick={() => selectCard(point)}
                        >
                            <div className={styles.cardContent}>
                                {textInfo.points[point.key]}
                            </div>
                            <CustomCheckbox
                                data={{
                                    "checked": activeCards.includes(point.key),
                                    "disabled": false
                                }}
                                color={themeData.checkboxCardColor}
                                checkCard={()=>{}}
                            />
                        </div>
                    )) }
                </div>
            )}

            <button
                className={`${styles.button} ${!activeCards.length > 0 ? styles.activeButton : ''}`}
                onClick={selectNoCard}
            >
                {textInfo.points.noneAbove}
            </button>
            <ContinueButton
                customStyles={buttonStyle}
                disableButton={countSelectedCards === 0}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default StatementDescribe;