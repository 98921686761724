import { useState, useEffect } from "react";
import Slider from '@mui/material/Slider';
import "./StepSwiper.scss";

const StepSwiper = props => {
    const { swiperRef, currentSlide, sliderCount } = props;
    const [step, setStep] = useState(currentSlide);
    const [colorThumb, setColorThumb] = useState("blue");
    
    const changeStep = value => {
        swiperRef.current.slideTo(value);
        setStep(value);
    }

    function findColour(value) {
        switch(value){
            case 1:
                return 'orange'
            case 2:
                return 'yellow'
            case 3:
                return 'green'
            case 4:
            case 5:
                return 'blue'
            case 0:
            default:
                return 'red'
        }
    }

    useEffect(() => {
        setColorThumb(findColour(step))
    }, [step, sliderCount])

    useEffect(() => {
        setStep(currentSlide)
    }, [currentSlide])

    return (
        <div className={`stepSwiperContainer`}>
            <Slider
                className={colorThumb}
                value={currentSlide}
                step={1}
                marks
                min={0}
                max={sliderCount - 1}
                onChange={e => changeStep(e.target.value)}
                sx={{

                }}
            />
        </div>
    )
}   

export default StepSwiper;