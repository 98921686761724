import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./BenefitsChair.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";
import {sanitizeData} from "../../../../utils/sanitizeHtmlText";
import LazyImage from "../../../UI/lazyImage/LazyImage";

const BenefitsChair = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.benefitsChair;
    const textInfo = strings.plan.benefitsChair;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_benefitsChair_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_benefitsChair_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title[pageInfo.title]}</h1>
            <div className={styles.description}
                 dangerouslySetInnerHTML={{ __html: sanitizeData(textInfo.description[pageInfo.description]) }}
            />
            <div className={styles.bannerArea}>
                <div className={`${styles.bannerBox} flex-row`}>
                    <div className={`${styles.bannerImages} flex-column`}>
                        <div className={styles.imageContainer}>
                            <LazyImage
                                className={styles.image}
                                srcWebp={pageInfo.banner.photoWebp}
                                src={pageInfo.banner.photo}
                                alt="image"
                            />
                        </div>
                        <div className={styles.subImageContainer}>
                            <LazyImage
                                className={styles.image}
                                srcWebp={pageInfo.banner.imageWebp}
                                src={pageInfo.banner.image}
                                alt="image"
                            />
                        </div>
                    </div>
                    <div className={styles.description}
                         dangerouslySetInnerHTML={{ __html: sanitizeData(textInfo.banner.description[pageInfo.banner.description]) }}
                    />
                </div>
            </div>
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default BenefitsChair;