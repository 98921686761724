import React, {useEffect, useRef, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./KindOfLife.module.scss";
import {isOverheight} from "../../../../utils/checkOverheight";
import {Swiper, SwiperSlide} from "swiper/react";
import LazyImage from "../../../UI/lazyImage/LazyImage";
import StepSwiper from "./stepSwiper/StepSwiper";

const KindOfLife = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.kindOfLife;
    const textInfo = strings.plan.kindOfLife;
    const [currentSlide, setCurrentSlide] = useState(pageInfo?.points?.length || 0);
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}
    const swiperRef = useRef();

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_kindOfLife_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_kindOfLife_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped', {answer: currentSlide + 1});
        const answer = new Map();
        answer.set("key", "kindOfLife");
        answer.set("value", currentSlide + 1);
        nextStep();
    }
    const changeSlide = value => {
        setCurrentSlide(value)
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title[pageInfo.title]}</h1>
            {pageInfo.points && pageInfo.points.length > 0 && (
                <div className={styles.bodySlider}>
                    <Swiper
                        className={styles.slider}
                        slidesPerView={1}
                        centeredSlides={true}
                        lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
                        initialSlide={pageInfo.points.length}
                        onSlideChange={slide => changeSlide(slide.activeIndex)}
                        onSwiper={(swiper) => swiperRef.current = swiper}
                    >
                        {pageInfo.points.map((slide, index) => (
                            <SwiperSlide className={styles.swiperSlide} key={index} >
                                <div className={styles.imageBox}>
                                    <div className={styles.imageContainer}>
                                        <LazyImage
                                            className={styles.image}
                                            srcWebp={slide.imageWebp}
                                            src={slide.image}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className={styles.slideText}>
                                    {textInfo.points[slide.key].text}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={styles.stepSlider}>
                        <StepSwiper
                            swiperRef={swiperRef}
                            currentSlide={currentSlide}
                            sliderCount={pageInfo.points.length}
                        />
                    </div>
                </div>
            )}
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}

export default KindOfLife;