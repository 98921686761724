import styles from "./PaymentRateCards.module.scss";
import RateCard from "../rateCard/RateCard";
import GetButton from "../getProgramButton/GetButton";
import RefundBox from "../refundBox/RefundBox";

const PaymentRateCards = props => {
    const {
        textButton,
        nextPage,
        rateCards,
        activeRate,
        setActiveRateCard,
        pageInfo,
        discount,
        style,
        themeData,
        infoText
    } = props;

    const mainContainerClass = `${styles.cardsContainer} ${styles[`${style}Campaign`]} flex-column`

    const showInfoBox = (infoText === null || infoText === undefined) || infoText;

    return (
       <>
           <div className={mainContainerClass}>
               {
                   rateCards.map(card =>
                       <RateCard
                           key={card.id}
                           activeCard={activeRate}
                           setActiveCard={setActiveRateCard}
                           pageInfo={pageInfo}
                           card={card}
                           discount={discount}
                           style={style}
                           themeData={themeData}
                           single={rateCards.length === 1}
                       />)
               }
           </div>
          { showInfoBox && <RefundBox style={style} theme={themeData.pageContainer} />}
           <GetButton text={textButton} nextPage={nextPage} style={style} themeData={themeData} withImage={true} />
       </>
      )
  }

export default PaymentRateCards;
