import React, {useEffect, useState} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../../UI/continueButton/ContinueButton";
import strings from "../../../../assets/localization/strings.jsx";
import styles from "./MeetInstructors.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/navigation";
import LazyImage from "../../../UI/lazyImage/LazyImage";
import {isOverheight} from "../../../../utils/checkOverheight";

const MeetInstructors = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.meetInstructors;
    const textInfo = strings.plan.meetInstructors;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    const swiperRef = React.useRef(null)
    const [swiperEl, setSwiperEl] = useState(swiperRef.current)
    const containerRef = React.useRef(null)
    const buttonStyle = isOverheight(containerRef.current?.clientHeight) ? { position: 'relative', marginTop: '45px', left: 'unset' } : {}

    useEffect(() => {
        if(swiperRef.current)
            setSwiperEl(swiperRef.current)
    }, [swiperRef]);

    // useEffect(() => {
    //     dataLayer.push({
    //                                     "event": "ga4event",
    //                                     "event_category": "screen_onboarding_meetInstructors_appeared",
    //                                     "funnel_type": data.campaign,
    //                                     "step": pageInfo.order
    //                                                               });
    //     amplitude.getInstance().logEvent('screen_onboarding_meetInstructors_appeared');
    // }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }

    return (
        <div
            ref={containerRef}
            className={mainContainerClass}
        >
            <h1>{textInfo.title}</h1>

            {pageInfo.slides && pageInfo.slides.length > 0 && (
                <div
                    ref={swiperRef}
                    className={styles.slidersBox}
                >
                    <Swiper
                        className={styles.swiperContainer}
                        slidesPerView={1}
                        centeredSlides={true}
                        navigation={true}
                    >
                        { pageInfo.slides.map((slide, i) => (
                            <SwiperSlide key={i} className={styles.swiperSlide}>
                                <div
                                    className={styles.slideContainer}
                                    style={{height: swiperEl ? swiperEl.clientHeight - 42 : '100%'}}
                                >
                                    <div className={styles.imageContainer}>
                                        <LazyImage
                                            className={styles.image}
                                            srcWebp={slide.imageWebp}
                                            src={slide.image}
                                            alt=""
                                        />
                                    </div>
                                    <div className={styles.infoContainer}>
                                        <div className={styles.name}>
                                            {textInfo.slides.name[slide.name]}
                                        </div>
                                        <div className={`${styles.link} flex-row`}>
                                            {`@${slide.link}`}
                                            <div className={styles.linkImage}>
                                                <img
                                                    className={styles.image}
                                                    src="/images/default/meetInstructors/link.svg"
                                                    alt="linkIcon"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.description}>
                                            {textInfo.slides.description[slide.description]}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            <ContinueButton
                customStyles={buttonStyle}
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default MeetInstructors;